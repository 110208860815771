import React from 'react'

import Layout from '../../../../components/Layout'

export default class AdminNavbar extends React.Component {
  render() {
    return (
      <Layout active="playbooks" noFooter>
        <iframe
          title="brand_infogram"
          src="https://infogram.com/campus-recruiting-playbook-1hxj48ze3pn94vg?live"
          className="full-page-iframe singleHeader"
        />
      </Layout>
    )
  }
}
